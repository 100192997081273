//** font import
@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&family=Oswald&family=Roboto&display=swap');

body {
   width: 100vw;
   min-height: 100vh;
   box-sizing: border-box;
   margin: 0;
   padding: 0;

   font-family: 'Roboto', sans-serif;

   h1,
   h2,
   h3,
   h4 {
      font-family: 'Oswald', sans-serif;
   }
}
