.resume {
   display: flex;
   flex-direction: row;
   height: 100%;

   .basic-infos {
      min-width: 20%;
      border-right: 1px solid fourthColor(1);
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      h1 {
         font-size: 1.8rem;
         padding: 0;
         margin: 0;
      }

      img {
         width: 60%;
         border: 2px solid fourthColor(0.3);
         padding: 0.3rem;
      }

      .social-links {
         display: flex;
         flex-direction: row;
         justify-content: space-evenly;
         gap: 2rem;

         a {
            color: thirdColor(1);
            border-radius: 10px;
            padding: 0.2rem;
            background-color: fourthColor(1);
            font-size: 1.7rem;
            position: relative;
            top: 0;
            right: 5%;
            transition: 0.5s ease-in;
         }

         a::before {
            content: '';
            position: absolute;
            border-radius: 10px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 2px solid fourthColor(1);
            z-index: -1000;
            transition: 0.2s ease-in-out;
         }

         a:hover::before {
            top: calc(2px * -3);
            left: calc(2px * -3);
            right: calc(2px * -3);
            bottom: calc(2px * -3);
         }
      }
   }

   ::-webkit-scrollbar {
      width: 0.6rem;
   }

   ::-webkit-scrollbar-track {
      background-color: firtsColor(1);
      border-radius: 100vw;
   }

   ::-webkit-scrollbar-thumb {
      background-color: secondColor(1);
      border-radius: 100vw;
   }

   .journey {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      width: 100%;
      max-height: 100%;
      overflow-y: scroll;

      .description {
         p {
            width: 80%;
            line-height: 1.4rem;
            text-transform: uppercase;
            text-align: justify;
            margin-inline: auto;
         }
      }

      h2 {
         text-align: center;
         text-transform: uppercase;
         letter-spacing: 0.2rem;
      }

      .experience {
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         gap: 2rem;

         & > section {
            padding: 1rem;
            box-shadow: 0 0 10px fourthColor(0.3);
            min-height: fit-content;
            flex: 1;

            .header {
               border-bottom: 1px solid fourthColor(0.4);
               height: 150px;
               display: flex;
               flex-direction: column;

               h4 {
                  font-size: 1.3rem;
                  margin-bottom: 0;
                  flex: 1;
               }

               h5 {
                  font-size: 1.1rem;
                  margin-top: 0;
               }
            }

            svg {
               font-size: 1.3rem;
               margin: 0 0.3em;
            }

            p {
               display: flex;
               align-items: center;
            }
         }
      }

      .education {
         display: flex;
         width: 50%;
         flex-direction: column;
         justify-content: center;
         align-items: center;

         & > section {
            text-align: center;
         }
      }
   }
}

//** responsive design
@media only screen and (max-width: 600px) {
   .resume {
      flex-direction: column;
      overflow-y: scroll;

      .basic-infos {
         width: 100%;
         border-right: none;
         border-bottom: 1px solid fourthColor(1);
         padding: 0 0 1rem 0;
         img {
            width: 40%;
         }
      }
      .journey {
         overflow-y: initial;
         padding: 0;

         .experience {
            & > section {
               display: flex;
               flex-direction: column;
               align-items: center;
               .header {
                  align-items: center;
               }
            }
         }
      }
   }
}
