.overview {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   height: 80%;
   max-width: 100%;

   .head {
      margin: 0;
      text-align: center;

      h1 {
         margin: 0 0 1rem 0;
         font-size: 2.5rem;
         text-transform: uppercase;
         letter-spacing: 0.2rem;
      }
   }

   .logo-animation {
      height: 100%;
      width: 100%;
   }
}

//** responsive scss

@media only screen and (max-width: 1050px) {
   .overview {
      .head {
         h1 {
            font-size: 2.2rem;
         }
      }
   }
}
