.projects {
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
   overflow-y: scroll;

   &::-webkit-scrollbar {
      width: 0.6rem;
   }

   &::-webkit-scrollbar-track {
      background-color: firtsColor(1);
      border-radius: 100vw;
   }

   &::-webkit-scrollbar-thumb {
      background-color: secondColor(1);
      border-radius: 100vw;
   }

   h1 {
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      font-size: 1.5rem;
      margin: 0.5rem 0;
   }

   & > ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 90%;
      justify-content: space-evenly;
      gap: 2rem;
      padding: 1rem 0;

      li {
         list-style-type: none;
         flex: 1 1 40%;
         padding: 1rem;
         box-shadow: 0 0 1rem secondColor(0.6);

         .body {
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 100%;
            flex: 1;

            & > * {
               text-align: center;
            }

            .header {
               width: 100%;
               height: 100%;

               .links {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  gap: 2rem;

                  a {
                     padding: 0.5rem;
                     border-radius: 2rem;
                     text-decoration: none;
                     color: thirdColor(1);
                     font-weight: bold;
                     background-color: fourthColor(1);
                     transition: ease-in 0.1s;

                     &:hover {
                        transform: scale(1.1) translateY(-0.2rem);
                        box-shadow: 0 2px 8px fourthColor(0.4);
                     }
                  }

                  p {
                     font-size: 0.8rem;
                     opacity: 0.5;
                     width: 50%;
                  }
               }

               .description {
                  display: flex;
                  justify-content: center;
                  p {
                     text-align: justify;
                  }
               }
            }

            .stack {
               margin-bottom: 2rem;
               ul {
                  margin-inline: auto;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  align-items: center;

                  li {
                     padding-bottom: 0.5rem;
                     width: 70%;
                     margin-inline: auto;
                     margin: 0;
                     box-shadow: none;
                     border-bottom: solid 1px thirdColor(0.2);
                  }
               }
            }
         }

         .loading {
            display: flex;
            justify-content: center;
            bottom: 0;
            width: 100%;
            letter-spacing: 0.2rem;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            color: thirdColor(0.4);
         }
      }
   }
}

@media only screen and (max-width: 380px) {
   .projects {
      ul {
         li {
            padding: 0;
         }
      }
   }
}
