.pagination {
   display: flex;
   justify-content: center;
   align-items: center;

   svg {
      color: thirdColor(0.8);
      font-size: 4rem;
   }

   button {
      margin: 10px 20px;
      padding: 0px;
      background-color: transparent;
      color: thirdColor(1);
      opacity: 0.5;
      border: none;
      cursor: pointer;
      font-size: 2rem;
      font-family: 'La Belle Aurore', cursive;
      transition: 0.5s;

      &::before {
         content: '';
         position: absolute;
         left: 0;
         right: 0;
         bottom: 0;
         height: 2px;
         background-color: fourthColor(0.6);
         transform: scaleX(0);
         transition: transform 0.8s ease-in-out;
      }

      &.active {
         transform: translateY(-20px) scale(1.2);
         opacity: 1;

         &::before {
            transform: scaleX(1);
         }
      }
   }
}

@media only screen and (max-width: 390px) {
   .pagination {
      button {
         font-size: 1.7rem;
      }
   }
}
