.container {
   width: 100vw;
   height: 100vh;
   margin: 0;
   padding: 0;
   display: flex;
   flex-direction: column;
   overflow: hidden;
   background-color: primaryColor(1);

   .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      color: thirdColor(1);
      height: 100vh;

      & > section {
         width: 70vw;
         height: 90%;
         border-radius: 1.5rem;
         overflow: hidden;
         position: absolute;
         box-shadow: 0px 0px 20px 0px fourthColor(0.3);
         background-color: secondColor(0.4);
         backdrop-filter: blur(30px);
      }

      .overview-page {
         background-color: transparent;
         width: fit-content;
         box-shadow: none;
         backdrop-filter: none;
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }
}

//** Responsive scss
@media only screen and (max-width: 800px) {
   .container {
      .content {
         & > section {
            width: 85vw;
         }
      }
   }
}
@media only screen and (max-width: 600px) {
   .container {
      .content {
         //Otherwise defaults mobile buttons when navigating hide pagination btns (Resume, Overview, Projects)
         height: 80vh;
         & > section {
            width: 90vw;
         }
      }
   }
}
