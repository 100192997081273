.skills {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 0.2rem;

   & > section {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      svg {
         font-size: 1.8rem;
      }

      div {
         border-radius: 20px;
         border: solid 1px;
         position: relative;
         width: 70%;
         height: 0.8rem;
         transition: 1s ease-in-out;
      }

      span {
         position: absolute;
         border-radius: 20px;
         width: 0;
         height: 100%;
         transition-duration: 1;
      }
   }

   .js {
      span {
         @include progressBar(#efd81d, 70%, 0s);
      }
   }
   .react {
      span {
         @include progressBar(#5ed3f3, 60%, 0.3s);
      }
   }
   .redux {
      span {
         @include progressBar(#7c41be, 60%, 0.6s);
      }
   }
   .scss {
      span {
         @include progressBar(#c76494, 60%, 0.9s);
      }
   }
   .ts {
      span {
         @include progressBar(#2f74c0, 50%, 1.2s);
      }
   }

   .node {
      span {
         @include progressBar(#509941, 40%, 1.5s);
      }
   }
   .mongo {
      span {
         @include progressBar(#4cab3d, 35%, 1.8s);
      }
   }
   .webpack {
      span {
         width: 0;
         @include progressBar(#2e6daa, 25%, 2.1s);
      }
   }
}

@media only screen and (max-width: 800px) {
   .skills {
      width: 70%;
   }
}
