.welcome-page {
   background-color: primaryColor(1);
   position: fixed;
   z-index: 1000;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   display: flex;
   justify-content: center;
   align-items: center;

   p {
      color: thirdColor(1);
      font-size: 3rem;
      text-transform: uppercase;
      text-align: center;
   }
}
