@function primaryColor($value) {
   $result: rgba(1, 4, 0, $value);
   @return $result;
}

@function secondColor($value) {
   $result: rgba(48, 51, 46, $value);
   @return $result;
}

@function thirdColor($value) {
   $result: rgba(255, 251, 252, $value);
   @return $result;
}

@function fourthColor($value) {
   $result: rgba(98, 187, 193, $value);
   @return $result;
}

@mixin progressBar($color, $progress, $delay) {
   width: $progress;
   background-color: $color;
   box-shadow: 0 0 1em 0 $color, 0 0 0.8em 0 $color;
   animation: progressAnimation 1s $delay cubic-bezier(0.81, -0.11, 0.87, 0.73) both;

   @keyframes progressAnimation {
      0% {
         width: 0%;
      }
   }
}
